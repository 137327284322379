import {
	computed,
	ref,
	getCurrentInstance,
} from '@vue/composition-api';
import isEqual from 'lodash.isequal';

import FONT_SETS from '@/assets/data/typography-styles-library.json';
import {
	PROPERTIES_FOR_EVALUATION,
	REQUIRED_TYPOGRAPHY_STYLE_PROPERTIES,
	TYPOGRAPHY_STYLE_ELEMENTS,
} from '@/components/builder-drawers/drawers/partials/stylesDrawer/typograpghy/constants';
import { useUserStyles } from '@/components/builder-drawers/drawers/partials/stylesDrawer/use/useUserStyles';
import {
	PROPERTY_FONT_PRIMARY,
	PROPERTY_FONT_SECONDARY,
} from '@/constants/globalStyles';
import BASE_TEMPLATE from '@/data/BaseTemplate.json';
import { extractFontName } from '@/utils/font';
import {
	cloneDeep,
	filterObject,
} from '@/utils/object';

const uneditedFontSets = cloneDeep(FONT_SETS);

// Shared state
const currentTypographyStyleLibrary = ref('');
const typographyStylesList = ref(FONT_SETS);
const uneditedTypographyStyleList = ref(uneditedFontSets);

export const useTypographyStylesLibrary = () => {
	const initialInstance = getCurrentInstance();
	const { currentTemplateUneditedStyles } = useUserStyles();
	const { $store } = getCurrentInstance() ?? {};
	const website = computed(() => getCurrentInstance()?.$store?.state?.website
		?? initialInstance.$store?.state?.website);
	const websiteStyles = computed(() => website.value.styles);
	const currentTemplate = computed(() => website.value?.meta.template);
	/**
	 * If typographyStylesId is not set takes currentTemplate instead as it means
	 * that no style has been selected yet and template id is the typographyStylesId
	 */
	const typographyStylesId = computed(
		() => website.value?.meta.typographyStylesId ?? currentTemplate.value,
	);

	const updateStyleProperties = (styles) => {
		Object.entries(styles).forEach(([textElementKey, textElementValue]) => {
			$store.commit('setStyleProperties', {
				element: textElementKey,
				value: textElementValue,
			});
		});
	};

	const addTypographyStylesToList = (title, textElementData) => {
		typographyStylesList.value.unshift({
			title,
			textElementData,
		});
	};

	const addTypographyStylesToUneditedList = (title, textElementData) => {
		uneditedTypographyStyleList.value.unshift({
			title,
			textElementData,
		});
	};

	const filterRequiredTypographyStyles = (styles) => {
		const pickedTypographyElements = filterObject(
			styles, ({ key }) => TYPOGRAPHY_STYLE_ELEMENTS.includes(key),
		);

		return Object.fromEntries(
			Object.entries(pickedTypographyElements).map(([elementKey, element]) => {
				const newElement = filterObject(
					element, ({ key }) => REQUIRED_TYPOGRAPHY_STYLE_PROPERTIES.includes(key),
				);

				return [
					elementKey,
					newElement,
				];
			}),
		);
	};

	const websiteTypographyStyles = computed(
		() => filterRequiredTypographyStyles(websiteStyles.value),
	);

	const currentUneditedTypographyStyles = computed(() => uneditedTypographyStyleList.value
		.find(({ title }) => title === typographyStylesId.value) ?? {});

	const isWebsiteTypographyStylesEdited = computed(
		() => {
			const pickedWebsitePropertiesForEvaluation = Object.fromEntries(
				Object.entries(websiteTypographyStyles.value).map(([elementKey, element]) => {
					const newElement = filterObject(
						element, ({ key }) => PROPERTIES_FOR_EVALUATION.includes(key),
					);

					return [
						elementKey,
						newElement,
					];
				}),
			);

			const pickedWebsiteUneditedPropertiesForEvaluation = Object.fromEntries(
				Object.entries(currentUneditedTypographyStyles.value.textElementData)
					.map(([elementKey, element]) => {
						const newElement = filterObject(
							element, ({ key }) => PROPERTIES_FOR_EVALUATION.includes(key),
						);

						return [
							elementKey,
							newElement,
						];
					}),
			);

			return !isEqual(
				pickedWebsitePropertiesForEvaluation, pickedWebsiteUneditedPropertiesForEvaluation,
			);
		},
	);

	const setTypographyStylesLibrary = (title, textElementData) => {
		currentTypographyStyleLibrary.value = {
			title,
			textElementData,
		};
	};

	const updateTypographyStylesLibrary = (title, textElementData, primaryFont, secondaryFont) => {
		setTypographyStylesLibrary(title, textElementData);
		updateStyleProperties(textElementData);

		$store.commit('setWebsiteMeta', {
			key: 'typographyStylesId',
			value: title,
		});
		$store.dispatch('fonts/UPDATE_FONT_STYLES', {
			family: extractFontName(primaryFont),
			type: PROPERTY_FONT_PRIMARY,
		});
		$store.dispatch('fonts/UPDATE_FONT_STYLES', {
			family: extractFontName(secondaryFont),
			type: PROPERTY_FONT_SECONDARY,
		});
	};

	const resetSelectedStyleInList = () => {
		const typographySylesToResetIndex = typographyStylesList.value
			.findIndex(({ title }) => title === typographyStylesId.value);

		typographyStylesList.value[typographySylesToResetIndex]
			.textElementData = currentUneditedTypographyStyles.value.textElementData;
	};

	const resetSelectedTypographyStyles = () => {
		updateStyleProperties(
			currentTypographyStyleLibrary.value.textElementData,
		);
	};

	const updateTypographyStyleListWithWebsiteStyles = () => {
		typographyStylesList.value = typographyStylesList.value
			.filter(({ title }) => title !== currentTypographyStyleLibrary.value.title);
		addTypographyStylesToList(
			typographyStylesId.value, websiteTypographyStyles.value,
		);
	};

	const filterOutExistingStyleInList = () => {
		typographyStylesList.value = typographyStylesList.value
			.filter(({ title }) => title !== typographyStylesId.value);
	};

	const addTemplateTypographyStylesToList = () => {
		setTypographyStylesLibrary(
			typographyStylesId.value,
			websiteTypographyStyles.value,
		);

		filterOutExistingStyleInList();

		addTypographyStylesToList(
			typographyStylesId.value, websiteTypographyStyles.value,
		);

		if (currentTemplateUneditedStyles.value) {
			const typographyElementsWithRequiredStyles = filterRequiredTypographyStyles(
				currentTemplateUneditedStyles.value,
			);

			if (currentTemplate.value !== typographyStylesId.value) {
				addTypographyStylesToList(
					currentTemplate.value, typographyElementsWithRequiredStyles,
				);
			}

			addTypographyStylesToUneditedList(
				currentTemplate.value, typographyElementsWithRequiredStyles,
			);
		} else {
			const { styles } = BASE_TEMPLATE;
			const typographyElementsWithRequiredStyles = filterRequiredTypographyStyles(styles);

			addTypographyStylesToUneditedList(
				typographyStylesId.value, typographyElementsWithRequiredStyles,
			);
		}
	};

	return {
		resetSelectedTypographyStyles,
		updateTypographyStylesLibrary,
		addTemplateTypographyStylesToList,
		updateTypographyStyleListWithWebsiteStyles,
		resetSelectedStyleInList,
		filterOutExistingStyleInList,
		typographyStylesId,
		isWebsiteTypographyStylesEdited,
		websiteTypographyStyles,
		currentTypographyStyleLibrary: computed(() => currentTypographyStyleLibrary.value),
		typographyStylesList: computed(() => typographyStylesList.value),
		uneditedTypographyStyleList: computed(() => uneditedTypographyStyleList.value),
	};
};
