import {
	PROPERTY_FONT_SIZE,
	PROPERTY_LINE_HEIGHT,
	PROPERTY_M_FONT_SIZE,
	PROPERTY_TEXT_DECORATION,
	PROPERTY_TEXT_TRANSFORM,
	TYPOGRAPHY_H1,
	TYPOGRAPHY_H2,
	TYPOGRAPHY_H3,
	TYPOGRAPHY_H4,
	TYPOGRAPHY_H5,
	TYPOGRAPHY_H6,
	TYPOGRAPHY_BODY_LARGE,
	TYPOGRAPHY_BODY,
	TYPOGRAPHY_BODY_SMALL,
	ELEMENT_GRID_BUTTON_PRIMARY,
	ELEMENT_GRID_BUTTON_SECONDARY,
	TYPOGRAPHY_NAV_LINK,
	PROPERTY_LETTER_SPACING,
	PROPERTY_FONT_FAMILY,
	PROPERTY_FONT_WEIGHT,
	PROPERTY_FONT_SECONDARY,
	PROPERTY_FONT_PRIMARY,
	FONT,
} from '@/constants/globalStyles';

const HEADING_DEFAULT_STYLES = {
	[PROPERTY_LINE_HEIGHT]: '1.3em',
	[PROPERTY_LETTER_SPACING]: 0,
	[PROPERTY_TEXT_TRANSFORM]: 'none',
	[PROPERTY_TEXT_DECORATION]: 'none',
};
const PARAGRAPH_DEFAULT_STYLE = {
	...HEADING_DEFAULT_STYLES,
	[PROPERTY_LINE_HEIGHT]: '1.5em',
};
const LINK_BUTTON_DEFAULT_STYLE = {
	[PROPERTY_FONT_SIZE]: '16px',
	[PROPERTY_M_FONT_SIZE]: '16px',
	...PARAGRAPH_DEFAULT_STYLE,
};

export const DEFAULT_TEXT_STYLES = {
	[TYPOGRAPHY_H1]: {
		[PROPERTY_FONT_SIZE]: '64px',
		[PROPERTY_M_FONT_SIZE]: '40px',
		...HEADING_DEFAULT_STYLES,
	},
	[TYPOGRAPHY_H2]: {
		[PROPERTY_FONT_SIZE]: '56px',
		[PROPERTY_M_FONT_SIZE]: '36px',
		...HEADING_DEFAULT_STYLES,
	},
	[TYPOGRAPHY_H3]: {
		[PROPERTY_FONT_SIZE]: '48px',
		[PROPERTY_M_FONT_SIZE]: '32px',
		...HEADING_DEFAULT_STYLES,
	},
	[TYPOGRAPHY_H4]: {
		[PROPERTY_FONT_SIZE]: '40px',
		[PROPERTY_M_FONT_SIZE]: '24px',
		...HEADING_DEFAULT_STYLES,
	},
	[TYPOGRAPHY_H5]: {
		[PROPERTY_FONT_SIZE]: '32px',
		[PROPERTY_M_FONT_SIZE]: '24px',
		...HEADING_DEFAULT_STYLES,
	},
	[TYPOGRAPHY_H6]: {
		[PROPERTY_FONT_SIZE]: '24px',
		[PROPERTY_M_FONT_SIZE]: '20px',
		...HEADING_DEFAULT_STYLES,
	},
	[TYPOGRAPHY_BODY_LARGE]: {
		[PROPERTY_FONT_SIZE]: '18px',
		[PROPERTY_M_FONT_SIZE]: '18px',
		...PARAGRAPH_DEFAULT_STYLE,
	},
	[TYPOGRAPHY_BODY]: {
		[PROPERTY_FONT_SIZE]: '16px',
		[PROPERTY_M_FONT_SIZE]: '16px',
		...PARAGRAPH_DEFAULT_STYLE,
	},
	[TYPOGRAPHY_BODY_SMALL]: {
		[PROPERTY_FONT_SIZE]: '14px',
		[PROPERTY_M_FONT_SIZE]: '14px',
		...PARAGRAPH_DEFAULT_STYLE,
	},
	[TYPOGRAPHY_NAV_LINK]: { ...LINK_BUTTON_DEFAULT_STYLE },
	[ELEMENT_GRID_BUTTON_PRIMARY]: { ...LINK_BUTTON_DEFAULT_STYLE },
	[ELEMENT_GRID_BUTTON_SECONDARY]: { ...LINK_BUTTON_DEFAULT_STYLE },
};

export const TYPOGRAPHY_STYLE_ELEMENTS = [
	TYPOGRAPHY_H1,
	TYPOGRAPHY_H2,
	TYPOGRAPHY_H3,
	TYPOGRAPHY_H4,
	TYPOGRAPHY_H5,
	TYPOGRAPHY_H6,
	TYPOGRAPHY_BODY_SMALL,
	TYPOGRAPHY_BODY,
	TYPOGRAPHY_BODY_LARGE,
	TYPOGRAPHY_NAV_LINK,
	FONT,
	ELEMENT_GRID_BUTTON_PRIMARY,
	ELEMENT_GRID_BUTTON_SECONDARY,
];

export const REQUIRED_TYPOGRAPHY_STYLE_PROPERTIES = [
	PROPERTY_FONT_FAMILY,
	PROPERTY_LETTER_SPACING,
	PROPERTY_LINE_HEIGHT,
	PROPERTY_FONT_SIZE,
	PROPERTY_M_FONT_SIZE,
	PROPERTY_TEXT_TRANSFORM,
	PROPERTY_FONT_WEIGHT,
	PROPERTY_TEXT_DECORATION,
	PROPERTY_FONT_PRIMARY,
	PROPERTY_FONT_SECONDARY,
];

export const PROPERTIES_FOR_EVALUATION = [
	PROPERTY_FONT_FAMILY,
	PROPERTY_LETTER_SPACING,
	PROPERTY_LINE_HEIGHT,
	PROPERTY_FONT_SIZE,
	PROPERTY_M_FONT_SIZE,
	PROPERTY_TEXT_TRANSFORM,
	PROPERTY_FONT_WEIGHT,
	PROPERTY_TEXT_DECORATION,
	PROPERTY_FONT_PRIMARY,
	PROPERTY_FONT_SECONDARY,
];
